<template>
  <div class="ambassador">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav>
    <box></box>
    <Sixbox></Sixbox>
    <four-boxa></four-boxa> -->
    <div class="search">
      <select
        name="country"
        id="country"
        @change="searchNews2(searchMess2)"
        v-model="searchMess2"
      >
        <option v-for="(item, i) in list.data" :key="i"> {{ item }}</option>
      </select>
      <!-- search -->
      <input
        class="searchInput"
        placeholder="搜索"
        type="text"
        v-model="searchMess"
      />
      <div class="line"></div>
      <img
        class="glass"
        :src="glassImg"
        alt=""
        @click="searchNews(searchMess)"
      />
    </div>
    <!-- 平台 -->
    <div class="platform">
      <img
        class="ambassadorImg"
        :src="ambassadorImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <div class="index">
        <div
          class="box"
          v-for="item in mess.data"
          :key="item"
          @click="goDetail(item.id)"
        >
          <img class="img" :src="item.image" alt="" />
          <p class="one">{{ item.name }}</p>
          <p class="two">{{ item.content }}</p>
          <p class="three">
            <span class="threeItem" v-for="item in item.item" :key="item">
              {{ item }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import axios from '@/utils/axios'

import Swiper from './modules/swiper.vue'
import SubNav from './modules/subNav.vue'
import Box from './modules/box.vue'
import Sixbox from './modules/SixBoxa.vue'
import FourBoxa from './modules/FourBoxa.vue'
import Message from '@/components/message/Message.vue'
import { ref } from 'vue'
import * as echarts from 'echarts'
import '../../../node_modules/echarts/map/china.js' // 引入中国地图数据
export default defineComponent({
  name: 'home',
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      ambassadorImg: require('@/assets/1new/赋能工程/赋能大使.jpg'),
      glassImg: require('@/assets/1new/赋能工程/2.jpg'),
    }
  },
  methods: {
    search() {
      axios
        .get('https://www.xsy985.com/api/expert/searchexpert/')
        .then(function (response) {
          // console.log(response, "search");
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    //
    goDetail(a: any) {
      this.$router.push({ name: 'ambassadorDetail', params: { id: a } })
    },
  },
  setup() {
    var count = reactive({ data: '' })
    var mess = reactive({ data: '' })
    var list = reactive({ data: '' })
    var searchMess = ref()
    var searchMess2 = ref('全部')

    axios
      .get(
        'https://www.xsy985.com/api/expert/expert?page=1&page_size=&expert_type=',
      )
      .then(function (response) {
        console.log('11111111111112', response.data)
        mess.data = response.data.results
      })
      .catch(function (error) {
        console.log(error)
      })
    axios
      .get('https://www.xsy985.com/api/expert/province')
      .then(function (response) {
        console.log(response, 'province')
        response.data.unshift('全部')
        list.data = response.data
      })
      .catch(function (error) {
        console.log(error)
      })
    //  axios
    //   .get(
    //     `https://www.xsy985.com/api/expert/searchexpert?page=1&page_size=3&service_province=${val}`
    //   )
    //   .then(function (response) {
    //     console.log(response, "搜索结果");
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    const searchNews = (val: any) => {
      console.log(val, '搜索内容')
      if (val == 'undefined') {
        return
      }
      axios
        .get(
          `https://www.xsy985.com/api/expert/searchexpert?page=1&name=${val}`,
        )
        .then(function (response) {
          console.log(response, '搜索结果')
          mess.data = response.data.results
          if (response.data.count == 0) {
            mess.data = '暂无'
          }
          window.scrollTo(0, 0)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
    // 搜索
    const searchNews2 = (val: any) => {
      // console.log(val, "搜索内容");
      if (val === '全部') {
        val = ''
      }
      axios
        .get(
          `https://www.xsy985.com/api/expert/searchexpert?page=1&service_province=${val}`,
        )
        .then(function (response) {
          console.log(response, '搜索结果')
          mess.data = response.data.results
          if (response.data.count == 0) {
            mess.data = '暂无'
          }
          window.scrollTo(0, 0)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
    return {
      mess,
      count,
      searchMess,
      searchMess2,
      searchNews,
      searchNews2,
      list,
    }
  },
})
</script>
<style lang="scss" scoped>
.ambassador {
  .ambassadorImg {
    width: 1200px;
    height: 100px;
  }
  .platform {
    width: 1200px;
    min-height: 300px;
    background-color: #fff;
    overflow: hidden;
  }
  .message {
    width: 1200px;
    // background-color: skyblue;
    display: block;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 56px;
  }
  .search {
    width: 1200px;
    height: 74px;
    background-color: #fff;
    margin-bottom: 30px;
    border-bottom: 1px solid #3ebbbd;
    position: relative;
    #country {
      width: 120px;
      height: 48px;
      border: 1px solid #3ebbbd;
      margin-top: 13px;
      &:active {
        border: none;
      }
    }
    .searchInput {
      width: 545px;
      height: 48px;
      border-radius: 24px;
      border: none;
      background-color: #f4f4f4;
      position: absolute;
      right: 25px;
      top: 13px;
      overflow: hidden;
      border: none;
      outline: none;
      font-size: 24px;
      color: #999;
      padding-left: 16px;
    }
    .glass {
      width: 37px;
      height: 37px;
      position: absolute;
      top: 22px;
      right: 48px;
    }
    .line {
      width: 3px;
      height: 37px;
      background-color: #3ebbbd;
      position: absolute;
      top: 22px;
      right: 92px;
    }
  }
  // ambassador
  .index {
    .box {
      float: left;
      width: 346px;
      height: 479px;
      position: relative;
      background-color: #fff;
      margin-bottom: 32px;
      margin-right: 80px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      .img {
        width: 346px;
        height: 314px;
        margin-bottom: 18px;
      }
      p {
        margin-bottom: 18px;
      }
      .one {
        font-size: 28px;
      }
      .two {
        font-size: 14px;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .three {
        font-size: 12px;
        line-height: 14px;
        color: #999;
        position: absolute;
        bottom: 0;
        .threeItem {
          border: 1px solid #666;
          border-radius: 4px;
          margin-right: 6px;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
</style>
