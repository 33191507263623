<template>
  <div class="index">
    <h3>新生涯的优势</h3>
    <div class="box">
      <div class="item" v-for="item in list" :key="item">
          <img class="img" :src="item.img" alt="">
          <p class="name">{{item.name}}</p>
          <p class="content">{{item.introduce}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "professor",
  data() {
    return {
      list: [
        {
          img: require("@/assets/合作与加盟/1.png"),
          name: "品牌影响力",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
        {
          img: require("@/assets/合作与加盟/2.png"),

          name: "专家2",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
        {
          img: require("@/assets/合作与加盟/3.png"),

          name: "专家一",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
        {
          img: require("@/assets/合作与加盟/4.png"),

          name: "专家2",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
        {
          img: require("@/assets/合作与加盟/5.png"),

          name: "专家一",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.index {
  // background-color: pink;
  // width: 1200px;
  // height: 200px;
  margin-top: 30px;
  h3{
    font-size: 30px;
    font-weight: normal;
    text-align: center;
  }
  .box{
    margin-top: 50px;
    width: 1080px;
    margin-left: 80px;
  display: block;
  overflow: hidden;
    .item{
      width: 180px;
      height: 360px;
      margin-right: 35px;
     float: left;
     text-align: center;
      .img{
        width: 130px;
        height: 130px;
      }
      .name{
        font-size: 24px;
        margin-top: 25px;
      }
      .content{
        margin-top: 15px;
        font-size: 13px;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}
</style>