<template>
  <div class="professor">
    <div class="swiper">
      <el-carousel height="1.40rem">
        <el-carousel-item v-for="item,  in imgList" :key="item">
          <img @click="goXSY()" class="img" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="centerTitle">
        生涯资讯
      </div> -->
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/赋能工程/赋能工程.jpg"),
      ],
    };
  },
  methods:{
    goXSY(){
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    }
  }
});
</script>
<style lang='scss'>
.professor{

.swiper {
  width: 1920px;
    height: 280px;
  margin-left: -360px;
  position: relative;
  // cursor: pointer;
  .img {
    width: 1920px;
    height: 280px;
  }
}
.centerTitle{
  position: absolute;
  top: 20px;
  left: 100px;
  font-size: 44px;
  color:#fff;
  z-index: 99;
}

.centerSubTitle{
  position: absolute;
  top: 20px;
  left: 100px;
  font-size: 14px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 497px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
}
</style>
